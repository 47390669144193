//
//
//
//
//
//
//
//
export default {
  props: {
    screenshots: Array
  }
};