//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    sliderData: Array
  },
  data: function data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 40,
        navigation: {
          nextEl: '.swiper-property__next'
        },
        breakpoints: {
          479: {
            slidesPerView: 2,
            spaceBetween: 16
          },
          799: {
            slidesPerView: 3,
            spaceBetween: 16
          },
          1199: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          1360: {
            slidesPerView: 5,
            spaceBetween: 25
          }
        }
      }
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (this.myswiper) this.myswiper.destroy();
  }
};