import { render, staticRenderFns } from "./GameScreens.vue?vue&type=template&id=31f91508&scoped=true&lang=pug&"
import script from "./GameScreens.vue?vue&type=script&lang=js&"
export * from "./GameScreens.vue?vue&type=script&lang=js&"
import style0 from "./GameScreens.vue?vue&type=style&index=0&id=31f91508&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f91508",
  null
  
)

export default component.exports