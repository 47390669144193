//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    symbols: Array
  }
};